.landing-placeholder {
    width: 100%;
    height: 100%;
    min-height: 80vh;
    padding: 8% 7%;
    background-position: 80%;
    background-size: 85%;
    background-repeat: no-repeat;
    background-position-x: center
}

.landing-placeholder span.ant-typography {
    font-size: 18px
}
.landing-placeholder h1.ant-typography {
    margin: 2px 30px !important
}

.ls5 {
    letter-spacing: 5px;
}

.ls10 {
    letter-spacing: 10px;
}

.app-login{
    /* background-color: #DDDEDE; */
    background-color: #F6F4F1;
    min-height: 100vh
}

.app-login .form-row{
    background-color: White;
    border-radius: 5px; 
    padding: 15px 0px;
    box-shadow: 5px 4px 12px rgba(135, 146, 245, 0.8);
}

.app-login-background {
    /* background-repeat: no-repeat;
    background-position-x: center */
}

.app-title-image{
    min-height: 10vh !important;
    background-position: 1%;
    background-size: 70%;
    background-repeat: no-repeat;
    background-position-x: center;
}

