@font-face {
  font-family: 'P-Grostek';
  src: local('P-Grostek'), url('./fonts/PowerGrotesk-Regular.otf') format('otf');

}

/* @import url('../node_modules/@fontsource/poppins'); */
/* @import url('./fonts/poppins/index.css'); */
body {
  margin: 0;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: thin;
  scrollbar-color: #d8d2d2 transparent;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
} */
/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif; */

.p-grostek{
  font-family: 'P-Grostek' !important;
}

@media all {
  .page-break {
    display: none;
  }
}

.ant-table-wrapper{
  scrollbar-width: thin;
  scrollbar-color: grey
}

.moz-scroller{
  scrollbar-width: thin;
  scrollbar-color: grey
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    /* margin-top: 1rem; */
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  /* margin: 10mm; */
}

