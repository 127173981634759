.bodyMargin{ 
    margin: 10px
}
.textCenter{ 
    text-align:'center' 
}
.imgDiv{ 
    width: 60%; 
    text-align: 'right' 
}
.marg20{ 
    margin-bottom: 20px
}

.signDiv { 
    border: 'solid black 1px'; 
    min-height:35px
}
.fSensitive {
    text-align:'center';
    margin-top: 100px
}
.weekendClass{
    background-color: #fafafa;
}

.cellSize table  {
    font-size: 10px !important;
    padding: 2px !important
}
.cellSize table > thead {
    font-size: 10px !important;
    padding: 2px !important
}
.cellSize table > tbody > tr  {
    font-size: 10px !important;
    padding: 2px !important
}
.cellSize table > thead > tr th {
    font-size: 10px !important;
    padding: 2px !important
}
.cellSize table > tbody > tr td {
    font-size: 10px !important;
    padding: 2px !important
}
.describe table  {
    padding: 2px 18px !important
}
.describe table tbody tr  {
    padding: 2px 5px !important
}
.describe table tbody tr th {
    padding: 2px 5px !important
}
.describe table tbody tr td {
    padding: 2px 5px !important
}


.sensitive {
    font-size: 12px;
    margin-bottom: 0px;
}

.b-sensitive{
    page-break-after :always;
    margin-top: 10px
}
.b-sensitive-bottom{
    page-break-after :always;
}

.signature {
    background-color: #deeaf6;
    min-height: 35px
}

/* Certifacates Style */
.certficate-describe{
    margin-bottom: 10px !important;
}
.certficate-describe th{
    width: 30%  !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
}
.certficate-describe.small th{
    padding-bottom: 5px !important;
    padding-top: 5px !important;
} 
.certficate-describe.large th{
    padding-bottom: 10px !important;
    padding-top: 10px !important;
} 
.certficate-describe.right th{
    text-align: right !important;
}
.certficate-describe th.paragraph{
    height: 150px !important;
}

.certficate-describe.representative th {
    width: 45% !important
}

.mb-40{ 
    margin-bottom: 40px 
}
.mb-20{ 
    margin-top: 20px 
}
.mt-10{ 
    margin-top: 10px 
}

.txt-center{
    text-align: center ;
}