.ant-table-container table > thead > tr th {
    font-weight: 600;
    font-size: medium;
}

/* .total-row{
    background-color: #C5D9F1 ;
    font-weight: 600;
} */
.wih-report div.ant-table-container table{
    border-color: #000 !important;
}
.wih-report div.ant-table-container table > thead > tr > th.ant-table-cell{
    background-color: #8DB4E2;
    border-color: #000 !important;
    padding: 0px 
}
.wih-report div.ant-table-container table > tbody > tr > td.ant-table-cell{
    padding-top: 0px; 
    padding-bottom: 0px; 
    padding-right: 0px; 
}

/* tr.title-row > td.ant-table-cell-fix-left{
    background-color: #C5D9F1; */
/* } */
/* tr.total-row > td.ant-table-cell-fix-left{
    background-color: #C5D9F1;
} */
th.ant-table-cell.whole { 
    text-align: center;
}
tr.data-title-row > td.ant-table-cell.data-title-row{
    padding-left: 20px !important;
}
tr.title-row > td.ant-table-cell{
    font-weight: 600;
}
td.fin-total.ant-table-cell {
    background-color: #C5D9F1;
} 

tr.total-row > td.ant-table-cell{
    background-color: #C5D9F1;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    font-weight: 600;
}

/* .ant-table-wrapper.TimeSheetTable {
    height: 75vh;
} */

/* .ant-table-wrapper.TimeSheetTable
    table
    tbody.ant-table-tbody
    tr.ant-table-row.ant-table-row-level-0
    td.ant-table-cell
     {
    padding: 0;
} */

div.timeSheet-table div.ant-table-body{
    scrollbar-width: thin;
}

div.timeSheet-table div.ant-table-content {
    overflow-x: hidden !important;
}

div.timeSheet-table div.ant-table-content:hover {
    overflow-x: scroll !important;
}

div.timeSheet-table div.ant-table-content::-webkit-scrollbar-track-piece {
    margin-left: 300px;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #d8d2d2;
    border-radius: 10px;
}

/* Editable Cell */
.editable-cell {
    position: relative;
}
  
.editable-cell-value-wrap {
/* padding: 5px 12px; */
cursor: pointer;
}
  
.editable-cell:hover .editable-cell-value-wrap {
/* padding: 4px 11px; */
border: 1px solid #d9d9d9;
border-radius: 2px;
}
  
[data-theme='dark'] .editable-cell:hover .editable-cell-value-wrap {
border: 1px solid #434343;
}
  
.expandtable-margin .ant-table.ant-table-small.ant-table-bordered{
    margin-left: 0px !important;
}

.table-inputNumber-border{
    font-size: 12px;
    border: 0px; 
}

.table-background-row-color tr.actual {
background-color: #a0df7d;
}

.table-background-row-color tr.actual:hover {
background-color: #a0df7d !important;
}

.table-background-row-color tr.actual:hover .ant-table-cell-row-hover {
background-color: #a0df7d;
}