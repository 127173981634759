.ant-upload.ant-upload-btn{
  padding: 0 !important;
}
.upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-right: 8px;
  }
  
.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}

.ant-upload-drag-icon{
  margin-bottom: 0 !important;
}