
button.ant-btn.success{
    background-color: #4caf50;
    color: #fff 
}
button.ant-btn.not-success{
    background-color: #ad2102;
    color: #fff 
}

.orange-color {
    background-color: #f37748;
    border: #f37748
}
.orange-color:hover, .orange-color:focus, .orange-color:active{
    background-color: #ff7f50;
    border: #ff7f50
}

button.ant-btn.success[disabled] , .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    background-color: rgb(245, 245, 245);
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
}
button.ant-btn.not-success[disabled] , .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    background-color: rgb(245, 245, 245);
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
}
span.disabledanticon{
    cursor: not-allowed;
}

span.anticon[disabled]{
    pointer-events: none; 
}

.pop-confirm-menu{
    padding: 1px;
}
.pop-confirm-menu div{
    padding: 4px 11px;
}
.pop-confirm-menu.ant-dropdown-menu-item-disabled{
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #fff !important;
}