textarea {
  font-size: 0.8rem;
  letter-spacing: 1px;
}
textarea {
  padding: 10px;
  line-height: 1.5;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px #999;
}
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 300px;
  margin-right: 8px;
}

span.upload-list-inline div.ant-upload.ant-upload-select.ant-upload-select-text{
  display: none !important;
}

.upload-list-inline [class*="-upload-list-rtl"] .ant-upload-list-item {
  float: right;
}
