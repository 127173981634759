aside.ant-layout-sider-light ul.ant-menu{
     background: #F6F4F1 !important;
}

aside.ant-layout-sider-light ul.ant-menu
li.ant-menu-item-selected {
    background-color: #FEFEFE !important
}
aside.ant-layout-sider-light ul.ant-menu
li.ant-menu-submenu-selected {
    background-color: #FEFEFE !important
}

.sub-menu-font{
    font-size: 12px;
}